@import './imports/global';

/**
 * Quick search for header
 */

.aui-header .aui-quicksearch {
    //
    // Variables
    //
    @aui-header-quicksearch-color: rgba(255, 255, 255, 0.6);
    @aui-header-quicksearch-bg-color: @ak-color-N90A;
    @aui-header-quicksearch-focus-color: @ak-color-B50;
    @aui-header-quicksearch-focus-bg-color: @ak-color-N90A;
    @aui-header-quicksearch-focus-outline: none;
    @aui-header-quicksearch-width: 170px;
    @aui-header-quicksearch-border-radius: 3px;
    @aui-header-quicksearch-box-shadow: none;
    @aui-header-quicksearch-icon: @aui-glyph-search-small;

    @icon-height: @aui-icon-size-small;
    @icon-width: @aui-icon-size-small;
    @icon-right-indent: @icon-width;

    @margin-top: 5px + @aui-header-padding-y;
    @padding-left-right: 10px;
    @text-padding-right: @icon-right-indent + @padding-left-right;
    // this fudging number pushes the text slightly higher so it "lines up" with the text in menu items and buttons.
    // it's 1px too low in Chrome, and 1px too high in Firefox and IE11. A compromise, if you will.
    @text-baseline-fudge: 1px;

    //
    // Layout concerns:
    // The container needs to be relative so we can absolutely position the icon within it.
    // We use flexbox so that vertical alignment is handled by the browser rather than magic margin numbers.
    //
    display: flex;
    justify-content: flex-end; // push to the RHS of the container.
    align-items: center; // vertically center the elements.
    position: relative;

    // ...and some cosmestic stuff.
    padding: 0 @aui-grid;
    color: @aui-header-quicksearch-color;

    input {
        background: @aui-header-quicksearch-bg-color;
        border: none;
        border-radius: @aui-header-quicksearch-border-radius;
        box-shadow: none;
        box-sizing: border-box;
        color: inherit;
        height: 2.143em; // 30px effective - need height in ems so that user-specified font-sizes apply
        font-family: inherit;
        font-size: inherit;
        margin: @margin-top 0 0 0;
        padding: 0 @text-padding-right @text-baseline-fudge 10px;
        vertical-align: baseline;
        width: @aui-header-quicksearch-width;

        &:focus {
            background: @aui-header-quicksearch-focus-bg-color;
            color: @aui-header-quicksearch-focus-color;
            outline: @aui-header-quicksearch-focus-outline;
        }

        //
        // Placeholder styling
        // You have to one rule per vendor because user agents are required to ignore a rule with an unknown selector.
        // Since WebKit doesn’t know the proprietary Mozilla selector and vice versa, you have to include them separately.
        // See http://stackoverflow.com/questions/2610497/change-an-inputs-html5-placeholder-color-with-css#answer-2610741
        //
        &::-webkit-input-placeholder {
            color: @aui-header-quicksearch-color;
        }

        &::-moz-placeholder { // Firefox 19+
            color: @aui-header-quicksearch-color;
        }

        &:-ms-input-placeholder {
            color: @aui-header-quicksearch-color;
        }

        //focus placeholder

        &:focus {
            &::-webkit-input-placeholder {
                color: @aui-header-quicksearch-focus-color;
            }

            &::-moz-placeholder {  // Firefox 19+
                color: @aui-header-quicksearch-focus-color;
            }

            &:-ms-input-placeholder {
                color: @aui-header-quicksearch-focus-color;
            }
        }
    }

    // Override the browser appearance of type=text, type=search and type=button.
    input[type] {
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    // icon
    &::after {
        #aui.icon-pseudoelement(@aui-icon-font-family);

        // Layout concerns:
        // because of a flexbox parent + align-self, there's no need to
        // mess with margins or positioning relative to the top of the container; it's just done by flexbox :D
        align-self: center;
        pointer-events: none; // so the icon isn't clickable, so clicks on it will focus the input field
        margin-left: (-1 * @text-padding-right); // sneakily shift the icon inside the input field
        margin-right: @padding-left-right; // so as not to reduce the distance between the input field and the next element in the header

        // And now the actual icon concerns:
        content: @aui-header-quicksearch-icon;
        color: inherit;
        font-size: @aui-icon-size-small;
        height: @icon-height;
        width: @icon-width;
    }
}
