@import './imports/global';

/**
 * LINK
 */


a {
    color: @aui-link-color;
    text-decoration: @aui-link-decoration;
}

a:hover {
    color: @aui-link-color-hover;
    text-decoration: @aui-link-decoration-active;
}

a:focus,
a:active {
    text-decoration: @aui-link-decoration;
}

a:active {
    color: @aui-link-color-active;
}
