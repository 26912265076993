.aui-iconfont-add::before {
    content: "\f101";
}

.aui-iconfont-add-comment::before {
    content: "\f102";
}

.aui-iconfont-approve::before {
    content: "\f104";
}

.aui-iconfont-menu::before {
    content: "\f105";
}

.aui-iconfont-chevron-double-down::before {
    content: "\f106";
}

.aui-iconfont-chevron-double-left::before {
    content: "\f107";
}

.aui-iconfont-chevron-double-right::before {
    content: "\f108";
}

.aui-iconfont-chevron-double-up::before {
    content: "\f109";
}

.aui-iconfont-attachment::before {
    content: "\f10a";
}

.aui-iconfont-documents::before {
    content: "\f10f";
}

.aui-iconfont-refresh::before {
    content: "\f119";
}

.aui-iconfont-calendar::before {
    content: "\f11a";
}

.aui-iconfont-cross::before {
    content: "\f11b";
}

.aui-iconfont-chevron-right::before {
    content: "\f11c";
}

.aui-iconfont-comment::before {
    content: "\f11d";
}

.aui-iconfont-settings::before {
    content: "\f11e";
}

.aui-iconfont-confluence::before {
    content: "\f11f";
}

.aui-iconfont-copy::before {
    content: "\f120";
}

.aui-iconfont-trash::before {
    content: "\f122";
}

.aui-iconfont-upload::before {
    content: "\f123";
}

.aui-iconfont-detail-view::before {
    content: "\f124";
}

.aui-iconfont-arrow-left::before {
    content: "\f125";
}

.aui-iconfont-arrow-right::before {
    content: "\f126";
}

.aui-iconfont-sign-in::before {
    content: "\f12a";
}

.aui-iconfont-import::before {
    content: "\f12b";
}

.aui-iconfont-branch::before {
    content: "\f127";
}

.aui-iconfont-commits::before {
    content: "\f12c";
}

.aui-iconfont-folder-filled::before {
    content: "\f131";
}

.aui-iconfont-create-fork::before {
    content: "\f133";
}

.aui-iconfont-export::before {
    content: "\f134";
}

.aui-iconfont-submodule::before {
    content: "\f139";
}

.aui-iconfont-tag::before {
    content: "\f13a";
}

.aui-iconfont-incomplete-build::before {
    content: "\f13c";
}

.aui-iconfont-plan-disabled::before {
    content: "\f13d";
}

.aui-iconfont-queued-build::before {
    content: "\f13e";
}

.aui-iconfont-document::before {
    content: "\f13f";
}

// Disabled since the "arrow-down" is using "arrow-down-small" for the backward compatibility
//.aui-iconfont-arrow-down::before {
//    content: "\f140";
//}
.aui-iconfont-new-arrow-down::before {
    content: "\f140";
}

.aui-iconfont-edit-filled::before {
    content: "\f142";
}

.aui-iconfont-center-alignment::before {
    content: "\f144";
}

.aui-iconfont-left-alignment::before {
    content: "\f145";
}

.aui-iconfont-right-alignment::before {
    content: "\f146";
}

.aui-iconfont-bold::before {
    content: "\f147";
}

.aui-iconfont-text-color::before {
    content: "\f148";
}

.aui-iconfont-emoji::before {
    content: "\f149";
}

// Disabled since the "help" is using "question-circle" for the backward compatibility
//.aui-iconfont-help::before {
//    content: "\f14a";
//}
.aui-iconfont-new-help::before {
    content: "\f14a";
}

.aui-iconfont-horizontal-rule::before {
    content: "\f14b";
}

.aui-iconfont-indent-left::before {
    content: "\f14c";
}

.aui-iconfont-italic::before {
    content: "\f14d";
}

.aui-iconfont-page-layout-toggle::before {
    content: "\f14e";
}

.aui-iconfont-bullet-list::before {
    content: "\f14f";
}

.aui-iconfont-number-list::before {
    content: "\f150";
}

.aui-iconfont-table-of-contents::before {
    content: "\f151";
}

.aui-iconfont-mention::before {
    content: "\f152";
}

.aui-iconfont-indent-right::before {
    content: "\f153";
}

.aui-iconfont-advanced::before {
    content: "\f154";
}

.aui-iconfont-symbol::before {
    content: "\f155";
}

.aui-iconfont-table::before {
    content: "\f156";
}

.aui-iconfont-task::before {
    content: "\f157";
}

.aui-iconfont-underline::before {
    content: "\f158";
}

.aui-iconfont-email::before {
    content: "\f159";
}

.aui-iconfont-error::before {
    content: "\f15a";
}

.aui-iconfont-chevron-down::before {
    content: "\f15b";
}

.aui-iconfont-flag::before {
    content: "\f165";
}

.aui-iconfont-vid-full-screen-on::before {
    content: "\f166";
}

.aui-iconfont-group::before {
    content: "\f167";
}

.aui-iconfont-more-vertical::before {
    content: "\f168";
}

.aui-iconfont-question-circle::before {
    content: "\f169";
}

.aui-iconfont-home-circle::before {
    content: "\f16b";
}

.aui-iconfont-image::before {
    content: "\f16c";
}

.aui-iconfont-info-circle::before {
    content: "\f16f";
}

.aui-iconfont-jira::before {
    content: "\f170";
}

.aui-iconfont-like::before {
    content: "\f173";
}

.aui-iconfont-world::before {
    content: "\f175";
}

.aui-iconfont-link::before {
    content: "\f176";
}

.aui-iconfont-add-circle::before {
    content: "\f177";
}

.aui-iconfont-cross-circle::before {
    content: "\f178";
}

.aui-iconfont-lock-filled::before {
    content: "\f179";
}

.aui-iconfont-more::before {
    content: "\f17f";
}

.aui-iconfont-nav-children::before {
    content: "\f180";
}

.aui-iconfont-file::before {
    content: "\f183";
}

.aui-iconfont-quote::before {
    content: "\f186";
}

.aui-iconfont-redo::before {
    content: "\f187";
}

.aui-iconfont-search::before {
    content: "\f18c";
}

.aui-iconfont-share::before {
    content: "\f18e";
}

.aui-iconfont-shortcut::before {
    content: "\f18f";
}

.aui-iconfont-person-circle::before {
    content: "\f192";
}

.aui-iconfont-star-filled::before {
    content: "\f193";
}

.aui-iconfont-check::before {
    content: "\f194";
}

.aui-iconfont-insert-column-before::before {
    content: "\f196";
}

.aui-iconfont-remove-column::before {
    content: "\f197";
}

.aui-iconfont-insert-column-after::before {
    content: "\f198";
}

.aui-iconfont-copy-table-row::before {
    content: "\f199";
}

.aui-iconfont-cut-table-row::before {
    content: "\f19a";
}

.aui-iconfont-heading-column::before {
    content: "\f19b";
}

.aui-iconfont-heading-row::before {
    content: "\f19c";
}

.aui-iconfont-merge-table-cells::before {
    content: "\f19d";
}

.aui-iconfont-paste-table-row::before {
    content: "\f19f";
}

.aui-iconfont-remove-table::before {
    content: "\f1a0";
}

.aui-iconfont-insert-row-after::before {
    content: "\f1a1";
}

.aui-iconfont-remove-row::before {
    content: "\f1a2";
}

.aui-iconfont-insert-row-before::before {
    content: "\f1a3";
}

.aui-iconfont-split-merged-table-cells::before {
    content: "\f1a4";
}

.aui-iconfont-team-calendar::before {
    content: "\f1a5";
}

.aui-iconfont-recent::before {
    content: "\f1a6";
}

.aui-iconfont-undo::before {
    content: "\f1a7";
}

.aui-iconfont-vid-full-screen-off::before {
    content: "\f1a8";
}

.aui-iconfont-unlock-filled::before {
    content: "\f1a9";
}

// Disabled since the "star" is using "star-filled" for the backward compatibility
//.aui-iconfont-star::before {
//    content: "\f1aa";
//}
.aui-iconfont-new-star::before {
    content: "\f1aa";
}

// Disabled since the "watch" is using "watch-filled" for the backward compatibility
//.aui-iconfont-watch::before {
//    content: "\f1ab";
//}
.aui-iconfont-new-watch::before {
    content: "\f1ab";
}

// Disabled since the "arrow-up" is using "arrow-up-small" for the backward compatibility
//.aui-iconfont-arrow-up::before {
//    content: "\f1ac";
//}
.aui-iconfont-new-arrow-up::before {
    content: "\f1ac";
}

.aui-iconfont-person::before {
    content: "\f1ad";
}

.aui-iconfont-watch-filled::before {
    content: "\f1af";
}

.aui-iconfont-room-menu::before {
    content: "\f1b1";
}

.aui-iconfont-warning::before {
    content: "\f1b3";
}

.aui-iconfont-tray::before {
    content: "\f1b5";
}

.aui-iconfont-vid-pause::before {
    content: "\f1c1";
}

.aui-iconfont-swap::before {
    content: "\f1c9";
}

.aui-iconfont-component::before {
    content: "\f1cd";
}

.aui-iconfont-arrow-down-small::before {
    content: "\f1d3";
}

.aui-iconfont-arrow-up-small::before {
    content: "\f1d4";
}

.aui-iconfont-filter::before {
    content: "\f1d6";
}

.aui-iconfont-download::before {
    content: "\f1de";
}

.aui-iconfont-single-column::before {
    content: "\f1e8";
}

.aui-iconfont-two-column::before {
    content: "\f1e9";
}

.aui-iconfont-right-side-bar::before {
    content: "\f1ea";
}

.aui-iconfont-left-side-bar::before {
    content: "\f1eb";
}

.aui-iconfont-three-column-side-bars::before {
    content: "\f1ec";
}

.aui-iconfont-three-column::before {
    content: "\f1ed";
}

.aui-iconfont-location::before {
    content: "\f1ee";
}

.aui-iconfont-radio::before {
    content: "\f500";
}

.aui-iconfont-activity::before {
    content: "\f1f3";
}

.aui-iconfont-add-item::before {
    content: "\f1f4";
}

.aui-iconfont-addon::before {
    content: "\f1f5";
}

.aui-iconfont-app-access::before {
    content: "\f1f6";
}

.aui-iconfont-app-switcher::before {
    content: "\f1f7";
}

.aui-iconfont-arrow-down-circle::before {
    content: "\f1f8";
}

.aui-iconfont-arrow-down-left::before {
    content: "\f1f9";
}

.aui-iconfont-arrow-down-right::before {
    content: "\f1fa";
}

.aui-iconfont-arrow-left-circle::before {
    content: "\f1fb";
}

.aui-iconfont-arrow-right-circle::before {
    content: "\f1fc";
}

.aui-iconfont-arrow-up-circle::before {
    content: "\f1fd";
}

.aui-iconfont-audio-circle::before {
    content: "\f1fe";
}

.aui-iconfont-audio::before {
    content: "\f1ff";
}

.aui-iconfont-backlog::before {
    content: "\f200";
}

.aui-iconfont-billing-filled::before {
    content: "\f201";
}

.aui-iconfont-billing::before {
    content: "\f202";
}

.aui-iconfont-board::before {
    content: "\f203";
}

.aui-iconfont-book::before {
    content: "\f204";
}

.aui-iconfont-calendar-filled::before {
    content: "\f205";
}

.aui-iconfont-camera-filled::before {
    content: "\f206";
}

.aui-iconfont-camera-rotate::before {
    content: "\f207";
}

.aui-iconfont-camera-take-picture::before {
    content: "\f208";
}

.aui-iconfont-camera::before {
    content: "\f209";
}

.aui-iconfont-canvas::before {
    content: "\f20a";
}

.aui-iconfont-cell-color-underline::before {
    content: "\f20b";
}

.aui-iconfont-cell-color::before {
    content: "\f20c";
}

.aui-iconfont-check-circle-filled::before {
    content: "\f20d";
}

.aui-iconfont-check-circle::before {
    content: "\f20e";
}

.aui-iconfont-checkbox::before {
    content: "\f20f";
}

.aui-iconfont-chevron-down-circle::before {
    content: "\f210";
}

.aui-iconfont-chevron-left-circle::before {
    content: "\f211";
}

.aui-iconfont-chevron-left::before {
    content: "\f212";
}

.aui-iconfont-chevron-right-circle::before {
    content: "\f213";
}

.aui-iconfont-chevron-up-circle::before {
    content: "\f214";
}

.aui-iconfont-chevron-up::before {
    content: "\f215";
}

.aui-iconfont-clone-small::before {
    content: "\f216";
}

.aui-iconfont-code::before {
    content: "\f217";
}

.aui-iconfont-copy-table-column::before {
    content: "\f218";
}

.aui-iconfont-create-branch::before {
    content: "\f219";
}

.aui-iconfont-create-pull-request::before {
    content: "\f21a";
}

.aui-iconfont-credit-card::before {
    content: "\f21b";
}

.aui-iconfont-credit-card-filled::before {
    content: "\f21c";
}

.aui-iconfont-cut-table-column::before {
    content: "\f21d";
}

.aui-iconfont-dashboard::before {
    content: "\f21e";
}

.aui-iconfont-decision::before {
    content: "\f21f";
}

.aui-iconfont-discover-filled::before {
    content: "\f220";
}

.aui-iconfont-discover::before {
    content: "\f221";
}

.aui-iconfont-document-filled::before {
    content: "\f222";
}

.aui-iconfont-dropbox::before {
    content: "\f223";
}

// Disabled since the "edit" is using "edit-filled" for the backward compatibility
//.aui-iconfont-edit::before {
//    content: "\f224";
//}
.aui-iconfont-new-edit::before {
    content: "\f224";
}

.aui-iconfont-failed-build::before {
    content: "\f225";
}

.aui-iconfont-feedback::before {
    content: "\f226";
}

.aui-iconfont-folder::before {
    content: "\f227";
}

.aui-iconfont-followers::before {
    content: "\f228";
}

.aui-iconfont-following::before {
    content: "\f229";
}

.aui-iconfont-fork-small::before {
    content: "\f22a";
}

.aui-iconfont-gallery::before {
    content: "\f22b";
}

.aui-iconfont-google-drive::before {
    content: "\f22c";
}

.aui-iconfont-google::before {
    content: "\f22d";
}

.aui-iconfont-graph-bar::before {
    content: "\f22e";
}

.aui-iconfont-graph-line::before {
    content: "\f22f";
}

.aui-iconfont-highlights::before {
    content: "\f230";
}

.aui-iconfont-home-filled::before {
    content: "\f231";
}

.aui-iconfont-image-border::before {
    content: "\f232";
}

.aui-iconfont-image-resize::before {
    content: "\f233";
}

.aui-iconfont-info-filled::before {
    content: "\f234";
}

.aui-iconfont-insert-numbered-column::before {
    content: "\f235";
}

.aui-iconfont-invite-team::before {
    content: "\f236";
}

.aui-iconfont-issue-raise::before {
    content: "\f237";
}

.aui-iconfont-issue::before {
    content: "\f238";
}

.aui-iconfont-issues::before {
    content: "\f239";
}

.aui-iconfont-lightbulb-filled::before {
    content: "\f23a";
}

.aui-iconfont-lightbulb::before {
    content: "\f23b";
}

.aui-iconfont-link-filled::before {
    content: "\f23c";
}

.aui-iconfont-list::before {
    content: "\f23d";
}

.aui-iconfont-lock-circle-small::before {
    content: "\f23e";
}

.aui-iconfont-lock::before {
    content: "\f23f";
}

.aui-iconfont-marketplace::before {
    content: "\f240";
}

.aui-iconfont-multiple-commits::before {
    content: "\f241";
}

.aui-iconfont-needs-work::before {
    content: "\f242";
}

.aui-iconfont-notification-all::before {
    content: "\f243";
}

.aui-iconfont-notification-direct::before {
    content: "\f244";
}

.aui-iconfont-notification::before {
    content: "\f245";
}

.aui-iconfont-office-building-filled::before {
    content: "\f246";
}

.aui-iconfont-office-building::before {
    content: "\f247";
}

.aui-iconfont-open::before {
    content: "\f248";
}

.aui-iconfont-overview::before {
    content: "\f249";
}

.aui-iconfont-page-filled::before {
    content: "\f24a";
}

.aui-iconfont-page::before {
    content: "\f24b";
}

.aui-iconfont-paint-bucket::before {
    content: "\f24c";
}

.aui-iconfont-paste-table-column::before {
    content: "\f24d";
}

.aui-iconfont-pdf::before {
    content: "\f24e";
}

.aui-iconfont-people-group::before {
    content: "\f24f";
}

.aui-iconfont-people::before {
    content: "\f250";
}

.aui-iconfont-portfolio::before {
    content: "\f251";
}

.aui-iconfont-preferences::before {
    content: "\f252";
}

.aui-iconfont-progress::before {
    content: "\f253";
}

.aui-iconfont-pull-requests::before {
    content: "\f254";
}

.aui-iconfont-question-filled::before {
    content: "\f255";
}

.aui-iconfont-queues::before {
    content: "\f256";
}

.aui-iconfont-recent-filled::before {
    content: "\f257";
}

.aui-iconfont-repository-small::before {
    content: "\f258";
}

.aui-iconfont-running-build::before {
    content: "\f259";
}

.aui-iconfont-schedule-filled::before {
    content: "\f25a";
}

.aui-iconfont-schedule::before {
    content: "\f25b";
}

.aui-iconfont-screen::before {
    content: "\f25c";
}

.aui-iconfont-send::before {
    content: "\f25d";
}

.aui-iconfont-ship::before {
    content: "\f25e";
}

.aui-iconfont-sign-out::before {
    content: "\f25f";
}

.aui-iconfont-source::before {
    content: "\f260";
}

.aui-iconfont-subtask::before {
    content: "\f261";
}

.aui-iconfont-successful-build::before {
    content: "\f262";
}

.aui-iconfont-task-list::before {
    content: "\f263";
}

.aui-iconfont-text-color-underline::before {
    content: "\f264";
}

.aui-iconfont-tray-empty::before {
    content: "\f265";
}

.aui-iconfont-unlink::before {
    content: "\f266";
}

.aui-iconfont-unlock-circle::before {
    content: "\f267";
}

.aui-iconfont-unlock::before {
    content: "\f268";
}

.aui-iconfont-vid-audio-muted::before {
    content: "\f269";
}

.aui-iconfont-vid-audio-on::before {
    content: "\f26a";
}

.aui-iconfont-vid-backward::before {
    content: "\f26b";
}

.aui-iconfont-vid-forward::before {
    content: "\f26c";
}

.aui-iconfont-vid-full-connection-circle::before {
    content: "\f26d";
}

.aui-iconfont-vid-full-speaking-circle::before {
    content: "\f26e";
}

.aui-iconfont-vid-hang-up::before {
    content: "\f26f";
}

.aui-iconfont-vid-hd-circle::before {
    content: "\f270";
}

.aui-iconfont-vid-play::before {
    content: "\f271";
}

.aui-iconfont-vid-raised-hand::before {
    content: "\f272";
}

.aui-iconfont-vid-share-screen::before {
    content: "\f273";
}

.aui-iconfont-video-camera-off::before {
    content: "\f274";
}

.aui-iconfont-video-circle::before {
    content: "\f275";
}

.aui-iconfont-video-filled::before {
    content: "\f276";
}
